import * as Sentry from '@sentry/browser';
import React from 'react';

export class SentryWrapper extends React.Component {
    componentDidCatch(error, errorInfo) {
        this.setState({
            error
        });
        Sentry.configureScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
        });
        Sentry.captureException(error);
    }

    render() {
        return this.props.children;
    }
}