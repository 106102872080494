import React, { createContext, useContext, useReducer } from 'react';
import { SentryWrapper } from '@mc/common/lib/sentry-wrapper';
import { INFO } from '../components/severity';

export const StateContext = createContext();

export const StateProvider = ({ reducer, initialState, children }) => (
  <StateContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </StateContext.Provider>
);
export const useStateValue = () => useContext(StateContext);

export const rootStateWrapper = ({ element }) => {
  const initialState = {
    email: null,
    customer_info: {
      global_customer_id: null,
    },
    ruleChecks: null,
    highestSeverity: INFO,
    reportId: - 1,
    severityConfig: null,
    loading: false,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case 'setEmail':
        return {
          ...state,
          email: action.email,
          customer_info: action.customer_info,
          ruleChecks: action.ruleChecks,
          highestSeverity: action.highestSeverity,
          severityConfig: action.severityConfig,
          loading: false,
        };
      case 'setLoading':
        return {
          ...state,
          loading: action.loading,
        };
      case 'setReportId':
        return {
          ...state,
          reportId: action.reportId,
        };
      default:
        return state;
    }
  };

  return (
    <SentryWrapper>
      <StateProvider initialState={initialState} reducer={reducer}>
        {element}
      </StateProvider>
    </SentryWrapper>
  )
}
