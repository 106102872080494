// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alert-details-js": () => import("./../../../src/pages/alert-details.js" /* webpackChunkName: "component---src-pages-alert-details-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-send-email-js": () => import("./../../../src/pages/send-email.js" /* webpackChunkName: "component---src-pages-send-email-js" */),
  "component---src-pages-stop-email-js": () => import("./../../../src/pages/stop-email.js" /* webpackChunkName: "component---src-pages-stop-email-js" */)
}

