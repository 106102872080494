import { faExclamationCircle, faExclamationTriangle, faInfoCircle, faBan } from '@fortawesome/pro-solid-svg-icons'

export const BLACKLIST = 40;
export const CRITICAL = 30;
export const WARNING = 20;
export const INFO = 10;
export const riskLevels = {
    [BLACKLIST]: {
        severityMessage: 'This message is dangerous',
        display: 'REPORTED',
        faIcon: faBan,
        primaryColor: '#9A0007',
    },
    [CRITICAL]: {
        severityMessage: 'This message is suspicious',
        display: 'HIGH RISK',
        faIcon: faExclamationCircle,
        primaryColor: '#D32F2F',
    },
    [WARNING]: {
        severityMessage: 'This message could be suspicious',
        display: 'MEDIUM RISK',
        faIcon: faExclamationTriangle,
        primaryColor: '#F57C00',
    },
    [INFO]: {
        severityMessage: 'This message needs your attention',
        display: 'LOW RISK',
        faIcon: faInfoCircle,
        primaryColor: '#1976D2',
    },
};